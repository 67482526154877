'use client'
import { FOOTER_COPYRIGHT, FOOTER_ICON_LIST, FOOTER_SERVICE_INFO } from 'constants/footer'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import Link from 'next/link'

import { useParams, usePathname } from 'next/navigation'
import { useCallback, useMemo, useState } from 'react'
import styles from './Footer.module.scss'
import useKDTNavList from '@/features/showroom/hooks/useKDTNavList'
import ChannelService from '@/shared/utils/channeltalk'

const Footer = () => {
  const params = useParams()
  const pathname = usePathname()
  const isHiddenPathName = [
    '/card-test',
    '/card-test/complete',
    '/card-test/result',
    `/school/${params?.id}/apply`,
  ]
  const [isBootcampOpen, setIsBootcampOpen] = useState(false)
  const [isStudentStoryOpen, setIsStudentStoryOpen] = useState(false)
  const { staticSubMenu } = useKDTNavList()
  const channelService = ChannelService()

  const onBootcampOpenHandler = useCallback(() => {
    setIsBootcampOpen(!isBootcampOpen)
  }, [isBootcampOpen])

  const onStudentStoryOpenHandler = useCallback(() => {
    setIsStudentStoryOpen(!isStudentStoryOpen)
  }, [isStudentStoryOpen])

  const applyingClassList = useMemo(() => {
    return staticSubMenu ? [...Object.values(staticSubMenu)].flat() : []
  }, [staticSubMenu])

  return isHiddenPathName.includes(pathname) ? (
    <></>
  ) : (
    <div className="bg-[#111] px-5 pb-16 pt-12 lg:px-5 lg:py-7">
      <div className="mx-auto max-w-7xl space-y-6 lg:space-y-8">
        <section className="flex w-full lg:w-auto lg:justify-between">
          {/* 왼쪽 */}
          <article className="flex w-full flex-col lg:w-auto lg:justify-between">
            <div className="flex flex-col justify-between">
              <Image
                className="h-[18px] w-[153px] lg:w-[147.57px]"
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/bootcamp_logo_white.svg'
                }
                alt="멋사 부트캠프"
                width={147}
                height={18}
              />
              <ul className="mt-6 block lg:hidden">
                <li className="cursor-pointer list-none" onClick={onBootcampOpenHandler}>
                  <div className="flex items-center justify-between gap-2.5">
                    <p className="whitespace-pre-wrap py-3 text-base font-semibold text-white">
                      부트캠프
                    </p>
                    <ChevronDownIcon
                      className={`${
                        isBootcampOpen ? 'rotate-180 transform' : ''
                      } h-4 w-4 shrink-0 grow-0 text-gray-400`}
                    />
                  </div>
                  {isBootcampOpen && (
                    <ul className="flex w-full flex-row flex-wrap gap-y-3 py-3">
                      {applyingClassList.map(apply => (
                        <li
                          className="flex h-7 w-1/2 flex-row items-center justify-start"
                          key={apply.id}
                        >
                          <Link
                            href={
                              ['kdt-aiplusn-1', 'kdt-aiplusi-1'].includes(apply.id)
                                ? {
                                    pathname: '/pre-notice',
                                    query: { preNoticeId: apply.id },
                                  }
                                : `/school/${apply.id}`
                            }
                            className="flex h-full flex-row items-center justify-start"
                          >
                            <p className="whitespace-nowrap text-sm text-gray-400">{apply.title}</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                <li>
                  <Link href="/job-support">
                    <p className="whitespace-pre-wrap py-3 text-base font-semibold text-white">
                      취업지원
                    </p>
                  </Link>
                </li>
                <li className="cursor-pointer list-none" onClick={onStudentStoryOpenHandler}>
                  <div className="flex items-center justify-between gap-2.5">
                    <p className="whitespace-pre-wrap py-3 text-base font-semibold text-white">
                      수강생 스토리
                    </p>
                    <ChevronDownIcon
                      className={`${
                        isStudentStoryOpen ? 'rotate-180 transform' : ''
                      } h-4 w-4 shrink-0 grow-0 text-gray-400`}
                    />
                  </div>
                  {isStudentStoryOpen && (
                    <ul className="flex w-full flex-row flex-wrap gap-y-3 py-3">
                      <li className="flex h-7 w-1/2 flex-row items-center justify-start">
                        <Link
                          className="flex h-full flex-row items-center justify-start"
                          href="https://story.likelion.net/ko/categories/191b0433"
                        >
                          <p className="whitespace-nowrap text-sm text-gray-400">성공 스토리</p>
                        </Link>
                      </li>
                      <li className="flex h-7 w-1/2 flex-row items-center justify-start">
                        <Link
                          className="flex h-full flex-row items-center justify-start"
                          href="https://story.likelion.net/ko/categories/81d76cb7"
                        >
                          <p className="whitespace-nowrap text-sm text-gray-400">우수 프로젝트</p>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <Link href="/nebeca">
                    <p className="whitespace-pre-wrap py-3 text-base font-semibold text-white">
                      내일배움카드
                    </p>
                  </Link>
                </li>
              </ul>
              <h3 className="mb-[7px] mt-6 text-base font-semibold text-white lg:mb-1.5 lg:mt-5">
                상담 가능 시간
              </h3>
              <p className="text-[13px] text-neutral-400">
                평일 10:00 - 18:00, 점심시간 12:00 - 13:00 (주말/공휴일 휴무)
              </p>

              <button
                onClick={() => channelService?.show()}
                className="mt-4 inline-flex cursor-pointer flex-row items-center justify-center gap-2.5 rounded-md bg-trueGray-800 px-3.5 py-2.5 lg:max-w-[150px]"
              >
                <Image
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/footer_chat_icon.png'
                  }
                  alt="멋사 채널톡"
                  width={16}
                  height={16}
                  priority={true}
                />
                <span className="text-sm font-semibold text-white">1:1 채팅 문의하기</span>
              </button>
            </div>
          </article>
          {/* 오른쪽 */}
          <article className="hidden flex-row gap-4 lg:flex">
            <div className="flex flex-col gap-2">
              <h6 className="text-xs text-neutral-400">부트캠프</h6>
              <ul
                className={`${applyingClassList.length > 8 ? 'max-w-64' : 'max-w-[120px]'} flex flex-row flex-wrap gap-x-4 gap-y-[9px]`}
              >
                {applyingClassList.map(apply => (
                  <li className="flex w-[120px] flex-row items-center justify-start" key={apply.id}>
                    <Link
                      href={
                        ['kdt-aiplusn-1', 'kdt-aiplusi-1'].includes(apply.id)
                          ? {
                              pathname: '/pre-notice',
                              query: { preNoticeId: apply.id },
                            }
                          : `/school/${apply.id}`
                      }
                      className="flex h-full flex-row items-center justify-start"
                    >
                      <p className="whitespace-nowrap text-[13px] font-semibold text-white">
                        {apply.title}
                      </p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <h6 className="text-xs text-neutral-400">취업지원</h6>
              <ul className="flex w-[120px] flex-col gap-[9px]">
                <li className="flex flex-row items-center justify-start">
                  <Link href="/job-support">
                    <p className="whitespace-nowrap text-[13px] font-semibold text-white">
                      취업지원
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <h6 className="text-xs text-neutral-400">수강생 스토리</h6>
              <ul className="flex w-[120px] flex-col gap-[9px]">
                <li className="flex flex-row items-center justify-start">
                  <Link href="https://story.likelion.net/ko/categories/191b0433">
                    <p className="whitespace-nowrap text-[13px] font-semibold text-white">
                      성공 스토리
                    </p>
                  </Link>
                </li>
                <li className="flex flex-row items-center justify-start">
                  <Link href="https://story.likelion.net/ko/categories/81d76cb7">
                    <p className="whitespace-nowrap text-[13px] font-semibold text-white">
                      우수 프로젝트
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <h6 className="text-xs text-neutral-400">내일배움카드</h6>
              <ul className="flex w-[120px] flex-col gap-[9px]">
                <li className="flex flex-row items-center justify-start">
                  <Link href="/nebeca">
                    <p className="whitespace-nowrap text-[13px] font-semibold text-white">
                      내일배움카드 안내
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </article>
        </section>
        <section className="lg:border-t-[1px] lg:border-[#272727] lg:pt-5">
          <ul className="mb-3 flex space-x-3">
            {FOOTER_ICON_LIST.map(({ name, icon, href }) => (
              <li key={name} className="text-sm">
                <a
                  target="_blank"
                  href={href}
                  rel="noreferrer noopener"
                  className="flex h-7 w-7 items-center"
                >
                  <Image src={icon} width={28} height={28} alt={`${name} 아이콘`} />
                </a>
              </li>
            ))}
          </ul>
          <ul className="mb-1 flex items-center space-x-2">
            {FOOTER_SERVICE_INFO.map(({ name, href }, idx) => (
              <li key={`service_${name}`} className="flex items-center">
                <span
                  className={`mr-2 h-1 w-1 rounded-full bg-gray-500 ${idx === 0 ? 'hidden' : ''}`}
                />
                <Link
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="flex h-[34px] items-center justify-center"
                >
                  <span className="text-xs font-medium text-neutral-400">{name}</span>
                </Link>
              </li>
            ))}
          </ul>
          <article className="border-t-[1px] border-[#272727] pt-4 text-neutral-500 lg:border-0 lg:pt-0">
            <div className="flex flex-row flex-wrap">
              {FOOTER_COPYRIGHT.map((el, idx) => (
                <div
                  key={`copyright_${idx}`}
                  className={`inline-block text-xs text-gray-500 ${styles.corp_divider}`}
                >
                  <span>{el}</span>
                </div>
              ))}
              <div className="text-xs text-gray-500 lg:hidden">
                <span>주소 : 서울 종로구 종로3길17, 광화문D타워 D1동 16층, 17층</span>
              </div>
            </div>
            <div className="mt-4 flex flex-col space-y-0.5 text-xs text-gray-500 md:mt-1 md:flex-row md:space-x-2 md:space-y-0 lg:mt-1">
              <span className="hidden lg:inline">
                주소 : 서울 종로구 종로3길17, 광화문D타워 D1동 16층, 17층
              </span>
              <span>Copyright © 2024 멋쟁이사자처럼 All rights reserved.</span>
            </div>
          </article>
        </section>
      </div>
    </div>
  )
}

export default Footer
